.login {
    width: 400px;
    background-color: white;
    margin: auto;
}

.logo {
    width: 300px;
}

.submit {
    margin: auto;
}
