@media screen and (min-width: 1200px) {
    .sidebar-menu-desktop {
        display: block;
    }
    .sidebar-menu-mobile {
        display: none;
    }
}

@media screen and (max-width: 1200px) {
    .sidebar-menu-desktop {
        display: none;
    }
    .sidebar-menu-mobile {
        display: block;
    }
}

.burger-button {
    width: 50px;
    height: 50px;
}