@import url(https://fonts.googleapis.com/css2?family=Rajdhani:wght@300;500&display=swap);
body {
  margin: 0;
  font-family: 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F5F5F5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

svg {
  touch-action: auto;
}

button {
  outline: none !important;
  box-shadow: none !important;
}
#sample-canvas {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    z-index: -10;
}

.video-container {
  background-color: #0e0e0e; }
  .video-container .video-content {
    height: 250px;
    width: 33vw;
    padding: 10px; }
  .video-container .video-title {
    color: white; }

.video-responsive {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  overflow: hidden;
  margin-bottom: 50px; }

.video-responsive iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0; }

.download-icon {
  color: white;
  font-size: 8em; }

.welcome-container {
  position: relative;
  width: 100%;
  overflow: hidden; }

@media screen and (min-width: 1200px) {
  .welcome-container-box {
    padding-left: 4.0vh;
    padding-top: 0px; } }

@media screen and (max-width: 1200px) {
  .welcome-container-box {
    padding-left: 0;
    padding-top: 7.3vh; } }

.welcome-container::before {
  content: "";
  background-image: url(/img/logo.svg);
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: -180px;
  right: 0px;
  bottom: 0px;
  left: -350px;
  opacity: 0.3;
  transform: rotate(-15deg);
  z-index: -1; }

.welcome-slider {
  background-color: #005AA5;
  border-radius: 10px;
  opacity: .8;
  color: #F5F5F5;
  margin: 5px; }

.slider {
  height: auto;
  max-height: 400px;
  color: #1F1F1F; }

.slider-element {
  padding-left: 50px;
  padding-right: 50px; }

@media screen and (min-width: 1200px) {
    .sidebar-menu-desktop {
        display: block;
    }
    .sidebar-menu-mobile {
        display: none;
    }
}

@media screen and (max-width: 1200px) {
    .sidebar-menu-desktop {
        display: none;
    }
    .sidebar-menu-mobile {
        display: block;
    }
}

.burger-button {
    width: 50px;
    height: 50px;
}
@media screen and (min-width: 1200px) {
    .main-container-box {
        padding-left: 5.5vh;
        padding-top: 0px;
    }
}

@media screen and (max-width: 1200px) {
    .main-container-box {
        padding-left: 0;
        padding-top: 7.3vh;
    }
}
.login-container {
    height: 95vh;
	width: 100%;
	padding-top: 5vh;
	background: linear-gradient(45deg, #0466C8, #0353A4, #023E7D, #023E7D, #001845, #001233);
	background-size: 400% 400%;
	-webkit-animation: login-gradient 15s ease infinite;
	        animation: login-gradient 15s ease infinite;
	overflow-y: hidden;
}

@-webkit-keyframes login-gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

@keyframes login-gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
.login {
    width: 400px;
    background-color: white;
    margin: auto;
}

.logo {
    width: 300px;
}

.submit {
    margin: auto;
}

