.welcome-container {
    position: relative; 
    width: 100%;
    overflow: hidden;
}

@media screen and (min-width: 1200px) {
    .welcome-container-box {
        padding-left: 4.0vh;
        padding-top: 0px;
    }
}

@media screen and (max-width: 1200px) {
    .welcome-container-box {
        padding-left: 0;
        padding-top: 7.3vh;
    }
}

.welcome-container::before {
    content: "";
    background-image: url(/img/logo.svg);
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    top: -180px;
    right: 0px;
    bottom: 0px;
    left: -350px;
    opacity: 0.3;
    transform: rotate(-15deg);
    z-index: -1;
}

.welcome-slider {
    background-color: #005AA5;
    border-radius: 10px;
    opacity: .8;
    color: #F5F5F5;
    margin: 5px;
}

.slider {
    height: auto;
    max-height: 400px;
    color: #1F1F1F
}

.slider-element {
    padding-left: 50px;;
    padding-right: 50px;;
}