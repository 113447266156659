@media screen and (min-width: 1200px) {
    .main-container-box {
        padding-left: 5.5vh;
        padding-top: 0px;
    }
}

@media screen and (max-width: 1200px) {
    .main-container-box {
        padding-left: 0;
        padding-top: 7.3vh;
    }
}