@import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@300;500&display=swap');
body {
  margin: 0;
  font-family: 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F5F5F5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

svg {
  touch-action: auto;
}

button {
  outline: none !important;
  box-shadow: none !important;
}